export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat en Sexologie"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de résidence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialisez-vous en Sexologie"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez une expertise clinique appliquée en sexologie, avec notre programme à\ndistance de certification professionnelle !"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme de formation vous permet d’acquérir les compétences essentielles, pour contribuer au\nmaintien et à l'amélioration de la santé et du bien-être sexuels de la personne en interaction avec son\nenvironnement. Vous serez en mesure de prendre en charge avec une approche psychothérapeutique,\ndiverses dysfonctions sexuelles aussi bien auprès de l’individu qu’au sein du couple."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexualité"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre les concepts généraux, objectifs et composantes de la sexualité."])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproduction"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les aspects anatomo-physiologiques de la reproduction."])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversité sexuelle"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre et maîtriser les notions liées à la diversité sexuelle."])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexologie clinique"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluer et prendre en charge les dysfonctionnements sexuels féminins et masculins."])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psychothérapies"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les principes et techniques de mise en œuvre des traitements psychothérapeutiques\nen sexologie."])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thérapies de\ncouple"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les spécificités des approches sexothérapeutiques auprès des couples. "])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de la santé"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins ; chirurgiens ; infirmiers ; sages-femmes ;\npharmaciens ; paramédicaux ; étudiants en sciences de la santé."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres publics cibles"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexologues, Sophrologues, Thérapeutes de couple, Psychologues,\nPsychothérapeutes, Travailleurs sociaux, Assistants sociaux, Sociologues, Diplômés en\nsciences sociales et humaines…"])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique d’approche par compétences et s’insère\nparfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode\nsynchrone (classes virtuelles) et asynchrone (cours pré-enregistrés)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé d’experts internationaux en\nsuivi-évaluation."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60 heures de cours."])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du 1er septembre au 30 octobre 2025"])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin du programme, l’apprenant(e) est soumis(e) à une épreuve de contrôle de\nconnaissance en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat professionnel accrédité en\n« sexologie », qui est délivré aux apprenant(e)s ayant obtenu une note supérieure ou égale à\n60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de\nl’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée.\nLa date limite pour s’inscrire au programme est le 30 Avril 2024. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures\npertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de\nfinaliser leur inscription. Merci."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}